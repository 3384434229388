@tailwind base;
@tailwind components;
@tailwind utilities;

#content {
  padding-top: 70px;
}
.brand-logo > img {
  object-fit: contain;
  width: 110px;
}

.rdt_TableCell > div {
  white-space: break-spaces !important;
}

.arrowless-dropdown::after {
  content: none;
}

.marginright-3 {
  margin-right: 10px;
}

.width-300px {
  width: 300px;
}

.aside {
  overflow-y: scroll;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.time-message {
  font-size: 10px;
}

.time-history {
  width: 120px;
  text-align: right;
  right: 0;
}

.tab {
  margin-left: 15px;
  margin-right: 15px;
}

.message-box {
  min-width: 200px;
  max-width: 700px;
  word-break: break-all;
  /* width: 100%; */
}

.my-message {
  background-color: #1e1e2d;
}

.my-message-history {
  color: white;
}

.typing-box {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.chat-box {
  min-height: 500px;
  max-height: 750px;
  overflow: auto;
}

.styles_poll__1QbHf {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: inherit;
  padding: 10px;
}
.styles_poll__1QbHf * {
  box-sizing: border-box;
}
.styles_poll__1QbHf .styles_question__3qxRo {
  color: #3a3a3a;
  text-align: inherit;
  font-weight: normal;
  margin: 0;
  padding-bottom: 3px;
  border-bottom: 1px solid #6d4b943b;
}
.styles_poll__1QbHf .styles_answers__17597 {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 5px 0;
}
.styles_poll__1QbHf .styles_answers__17597 li {
  width: 100%;
  margin: 5px 0;
}
.styles_poll__1QbHf .styles_answers__17597 .styles_option__1sn1C,
.styles_poll__1QbHf .styles_answers__17597 .styles_result__SJJfE {
  font-weight: bold;
  border: 2px solid #000000;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 10px;
  transition: all 0.3s ease;
  outline: 0;
  font-family: inherit;
  background-color: inherit;
}
.styles_poll__1QbHf .styles_answers__17597 .styles_option__1sn1C:hover,
.styles_poll__1QbHf .styles_answers__17597 .styles_option__1sn1C:active {
  background: #3030303b;
}
.styles_poll__1QbHf
  .styles_answers__17597
  .styles_option__1sn1C.styles_purple__1m4Cl:hover,
.styles_poll__1QbHf
  .styles_answers__17597
  .styles_option__1sn1C.styles_purple__1m4Cl:active {
  background: #6d4b943b;
}
.styles_poll__1QbHf
  .styles_answers__17597
  .styles_option__1sn1C.styles_red__1nmnb:hover,
.styles_poll__1QbHf
  .styles_answers__17597
  .styles_option__1sn1C.styles_red__1nmnb:active {
  background: #ff28283b;
}
.styles_poll__1QbHf
  .styles_answers__17597
  .styles_option__1sn1C.styles_blue__3LfCr:hover,
.styles_poll__1QbHf
  .styles_answers__17597
  .styles_option__1sn1C.styles_blue__3LfCr:active {
  background: #283eff3b;
}
.styles_poll__1QbHf
  .styles_answers__17597
  .styles_option__1sn1C.styles_white__1BIUt:hover,
.styles_poll__1QbHf
  .styles_answers__17597
  .styles_option__1sn1C.styles_white__1BIUt:active {
  background: #ffffff3b;
}
.styles_poll__1QbHf
  .styles_answers__17597
  .styles_option__1sn1C.styles_cyan__2Qp2d:hover,
.styles_poll__1QbHf
  .styles_answers__17597
  .styles_option__1sn1C.styles_cyan__2Qp2d:active {
  background: #00bcdd3b;
}
.styles_poll__1QbHf .styles_answers__17597 .styles_result__SJJfE {
  height: 42px;
  padding: 0;
  cursor: default;
  position: relative;
}
.styles_poll__1QbHf
  .styles_answers__17597
  .styles_result__SJJfE
  .styles_fill__6jfwi {
  width: 0%;
  height: 100%;
  border-radius: 3px;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
}
.styles_poll__1QbHf
  .styles_answers__17597
  .styles_result__SJJfE
  .styles_labels__2rz-F {
  position: absolute;
  top: calc(19px - 9px);
  margin: 0 10px;
  font-size: 16px;
}
.styles_poll__1QbHf
  .styles_answers__17597
  .styles_result__SJJfE
  .styles_answer__1w8xb {
  margin-left: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.styles_poll__1QbHf
  .styles_answers__17597
  .styles_result__SJJfE
  .styles_answer__1w8xb.styles_vote__1ltqi::after {
  content: " \\2713";
}
.styles_poll__1QbHf .styles_votes__3IO-o {
  text-align: inherit;
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: #3030305e;
}


.border-b{
  border: 1px solid black;
}

.export-btn{
  width: 200px;
}

.overlay__background {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 999;
}

.overlay__container {
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 1000;
  padding: 30px;
  width: fit-content;
  height: fit-content;
  max-width: 300vh;
}


.overlay__controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.overlay__close:after {
  display: inline-block;
  content: "\00d7";
}

.overlay__close {
  border: none;
  background-color: transparent;
  font-size: 36px;
  cursor: pointer;
}

.overlay-content-scrollable {
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
  background-color: #fff;
}

.user-card {
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.user-card h2 {
  margin: 0;
  font-size: 16px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}

.buttonStyle {
  padding: 8px 16px;
  border: 1px solid #007BFF;
  background-color: #007BFF;
  color: #FFF;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

.buttonStyle:hover {
  background-color: #0056b3;
}

.buttonStyle:active {
  transform: scale(0.95);
}

.buttonStyleText {
  margin: 0;
}